import actionCreatorFactory from 'typescript-fsa';

import { StageId } from '../../api/stages';
import { StageManagerState } from '../../components/eventProvider/stageProviders/StageManager';

const actionCreator = actionCreatorFactory();

export const gameActions = {
  setHasStageFinished: actionCreator<{
    stage: number;
    finished: boolean;
  }>('GAME_SET_HAS_STAGE_FINISHED'),
  setRecordedStageStartTimes: actionCreator<Partial<Record<StageId, number>>>(
    'GAME_SET_RECORDED_STAGE_START_TIMES'
  ),
  setStageManagerState: actionCreator<Partial<StageManagerState>>(
    'GAME_SET_STAGE_MANAGER_STATE'
  ),
};
