import { User } from 'firebase/auth';
import { useSelector } from 'react-redux';

import { CustomClaimContent } from '../../api/firestoreTypes';

export const useCurrentUser: () => User | null = () =>
  useSelector(state => state.auth.user);
export const useIsUserInitialized = () =>
  useSelector(state => state.auth.userInitialized);
export const useIsUserEmailVerified = () =>
  useSelector(state => state.auth.user?.emailVerified === true);
export const useSelectedOrgId = (): string | null =>
  useSelector(state => state.auth.selectedOrgId);
export const useCustomClaim = (): CustomClaimContent | null =>
  useSelector(state => state.auth.customClaim);
