import { CommonBG, TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

type LoadingProps = Record<string, never>;
const Loading: React.FC<LoadingProps> = () => {
  return (
    <CommonBG>
      <Wrapper>
        <Text>認証しています。</Text>
      </Wrapper>
    </CommonBG>
  );
};

export default Loading;

const Wrapper = styled.div`
  position: relative;
  padding-top: 8rem;
`;

const Text = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 6rem;
`;
