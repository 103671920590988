import { DocumentReference, Timestamp, doc } from 'firebase/firestore';
import { useEffect } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useDispatch } from 'react-redux';

import { UserRecords } from '../api/firestoreTypes';
import { STAGE_TIME_LIMIT } from '../components/eventProvider/stageProviders/StageManager';
import { gameActions } from '../redux/actions/gameActions';
import { useCurrentUser } from '../redux/selectors/authSelectors';
import { useEventId } from '../redux/selectors/gameSelectors';
import { useGetServerTime } from '../redux/selectors/timeSelectors';
import { db } from '..';

export const stageIds = [1, 2, 3, 4, 5] as const;
export type StageId = typeof stageIds[number];

export const useStartTimesEffect = () => {
  const user = useCurrentUser();
  const eventId = useEventId();
  const dispatch = useDispatch();
  const getServerTime = useGetServerTime();
  const [userRecords, loading] = useDocumentData<UserRecords>(
    user !== null && eventId !== null
      ? (doc(
          db,
          'version/1/event_user_records',
          eventId,
          'users',
          user?.uid ?? 'userId'
        ) as DocumentReference<UserRecords>)
      : undefined
  );

  useEffect(() => {
    if (loading) return;

    const timestamp = userRecords?.startTime ?? null;
    if (timestamp === undefined || timestamp === null) {
      return;
    }
    const startTime = timestamp!.toMillis() / 1000;
    if (startTime + STAGE_TIME_LIMIT < getServerTime()) {
      dispatch(gameActions.setHasStageFinished({ stage: 1, finished: true }));
    }
    dispatch(gameActions.setRecordedStageStartTimes({ 1: startTime }));
  }, [userRecords, loading, dispatch, getServerTime]);
};
