import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  GetAllStage1TargetsQuery,
  useGetAllStage1TargetsQuery,
  useSubmitAnswer1Mutation,
} from '../../../../api/__generated__/stage1.generated';
import { useStage1Submission } from '../../../../api/stage1';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { appActions } from '../../../../redux/actions/appActions';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import * as Types from '../../../../types';
import CommonBG from '../../../uiElements/CommonBG';
import TimeGauge from '../../../uiElements/timeGauge/TimeGauge';
import StageManager from '../../stageProviders/StageManager';
import AchievedPanel from './AchievedPanel';
import DraggableArea from './DraggableArea';
import NewlyAchievedWindow from './NewlyAchievedWindow';

export type Target = Types.Stage1Target;

interface Stage1Props {}

const Stage1: React.FC<Stage1Props> = () => {
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const eventId = useEventId();
  const [submission] = useStage1Submission(user, eventId);

  const [ansStr, setAnsStr] = React.useState('');
  const [allTargets, setAllTargets] = React.useState<Target[]>([]);
  const achievedTargetsLoaded = React.useRef<boolean>(false);
  const [achievedTargets, setAchievedTargets] = React.useState<Target[]>([]);
  const [newlyAchievedTargets, setNewlyAchievedTargets] = React.useState<
    Target[]
  >([]);

  console.log('user', user);
  // 新規解放がない
  const [noNewTargetAchieved, setNoNewTargetAchieved] =
    React.useState<boolean>(false);
  // 不正入力
  const [ansInvalid, setAnsInvalid] = React.useState<boolean>(false);

  const onSubmitAnswer1Error = React.useCallback(() => {
    setAnsInvalid(true);
  }, [setAnsInvalid]);
  const [submitAnswer1] = useRetryableMutationWithUI(useSubmitAnswer1Mutation, {
    hookOptions: {
      onError: onSubmitAnswer1Error,
    },
    loading: {
      options: {
        text: '送信中...',
      },
    },
  });

  const onGetAllStage1TargetsCompleted = React.useCallback(
    (data: GetAllStage1TargetsQuery) => {
      setAllTargets(data.getAllStage1Targets);
    },
    [setAllTargets]
  );

  // Queryたたく: return valueは取る必要なし
  // たぶんon mountで実行
  useGetAllStage1TargetsQuery({
    onCompleted: onGetAllStage1TargetsCompleted,
  });

  const resetErrors = React.useCallback(() => {
    setAnsInvalid(false);
    setNoNewTargetAchieved(false);
  }, [setAnsInvalid, setNoNewTargetAchieved]);

  const findTargetById = React.useCallback(
    (id: number) => allTargets.find(v => v.targetId === id),
    [allTargets]
  );

  // 獲得済みターゲットの情報
  React.useEffect(() => {
    if (
      submission?.answers === undefined ||
      submission.answers.length === 0 ||
      allTargets.length === 0
    ) {
      return;
    }

    const lastAns = submission.answers[submission.answers.length - 1];

    const achievedIds = Array.from(
      new Set(submission.answers.map(ans => ans.achieved).flat())
    );

    const uniqAchievedTargets = achievedIds
      .map(id => findTargetById(id))
      .filter((target): target is Target => target !== undefined);

    // ページ表示時の初回読み込みだけ、獲得ウィンドウ表示しない
    if (!achievedTargetsLoaded.current) {
      achievedTargetsLoaded.current = true;
      setAchievedTargets(uniqAchievedTargets);
      return;
    }
    setAchievedTargets(achievedTargets => {
      const _newlyAchievedTargets = uniqAchievedTargets.filter(
        target =>
          achievedTargets.findIndex(t => t.targetId === target.targetId) === -1
      );
      if (_newlyAchievedTargets.length > 0) {
        // 新規達成
        setNewlyAchievedTargets(_newlyAchievedTargets);
      } else if (lastAns.achieved.length > 0) {
        // 達成あり、しかし新規なし
        setNoNewTargetAchieved(true);
      } else {
        // なにもなし
        setAnsInvalid(true);
      }
      return uniqAchievedTargets;
    });
  }, [submission, findTargetById, allTargets, setAchievedTargets]);

  // 送信
  const onSend = React.useCallback(() => {
    if (!eventId) return;
    resetErrors();

    // 最初の送信時まで過去の提出がloadされてなければ、されたことにしておく
    achievedTargetsLoaded.current = true;

    dispatch(appActions.setLoadingState({ visible: true, text: '送信中...' }));

    submitAnswer1({
      variables: {
        input: {
          eventId,
          answer: ansStr,
        },
      },
    });
  }, [ansStr, dispatch, eventId, resetErrors, submitAnswer1]);

  const onCloseNewlyAchievedWindow = React.useCallback(() => {
    setNewlyAchievedTargets([]);
  }, []);

  return (
    <StageManager stageId={1} finishOnTimeUp>
      <CommonBG>
        <Wrapper>
          <TimeGauge />
          <DraggableArea
            errorMessage={
              (ansInvalid && '等式が間違っています') ||
              (noNewTargetAchieved && 'すでにターゲットを解放済みです') ||
              ''
            }
            resetErrors={resetErrors}
            setAnsStr={setAnsStr}
            onSend={onSend}
          />
          <AchievedPanel achievedTargets={achievedTargets} />
          <NewlyAchievedWindow
            newlyAchievedTargets={newlyAchievedTargets}
            onClose={onCloseNewlyAchievedWindow}
          />
        </Wrapper>
      </CommonBG>
    </StageManager>
  );
};

const Wrapper = styled.div`
  height: 100vh;
  overflow: scroll;
  position: relative;
`;

export default Stage1;
