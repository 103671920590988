import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import CleanRecord from './CleanRecord';

interface EventRouterProps {}

export const AdminRouter: React.FC<EventRouterProps> = () => {
  return (
    <Routes>
      <Route path='/:eventId/:uid/cleanRecord' element={<CleanRecord />} />
    </Routes>
  );
};

export default AdminRouter;
