import { gql } from '@apollo/client';
import { User } from 'firebase/auth';
import { DocumentReference, doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

import { Game1 } from './firestoreTypes';
import { db } from '..';

export const stage1Schema = gql`
  mutation SubmitAnswer1($input: Answer1!) {
    submitAnswer1(input: $input) {
      achieved {
        targetId
        name
        score
      }
    }
  }

  query getAllStage1Targets {
    getAllStage1Targets {
      targetId
      name
      score
    }
  }
`;

export type Stage1Submission = Game1;

export const useStage1Submission = (
  user: User | null,
  eventId: string | null
): LoadingHook<Stage1Submission, Error> => {
  return useDocumentData<Stage1Submission>(
    user !== null && eventId !== null
      ? (doc(
          db,
          'version/1/submissions',
          eventId,
          user.uid,
          '1'
        ) as DocumentReference<Stage1Submission>)
      : undefined
  );
};

export const useStage1SubmissionByUID = (
  uid: string | null,
  eventId: string | null
): LoadingHook<Stage1Submission, Error> => {
  return useDocumentData<Stage1Submission>(
    uid !== null && eventId !== null
      ? (doc(
          db,
          'version/1/submissions',
          eventId,
          uid,
          '1'
        ) as DocumentReference<Stage1Submission>)
      : undefined
  );
};
