import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { StageId } from '../../api/stages';

export const useStageState = (props: { stageId: StageId }) =>
  useSelector(state => ({
    finished: state.game.hasStageFinished[props.stageId],
  }));
export const useStagesFinished = () =>
  useSelector(state => state.game.hasStageFinished);
export const useEventId = () =>
  useMatch('/events/:eventId/*')?.params.eventId ?? null;
export const useRecordedStartTime = () =>
  useSelector(state => state.game.recordedStageStartTime);
export const useStageManagerState = () =>
  useSelector(state => state.game.stageManagerState);
