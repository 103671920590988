import { gql } from '@apollo/client';
import {
  sendStageEnd,
  sendStageScore,
  sendStageStart,
} from '@riddler-co-jp/specc-auth-client';

import { app } from '..';

export const eventSchema = gql`
  mutation adminStageReset($input: AdminResetInput!) {
    adminStageReset(input: $input) {
      success
    }
  }
  mutation recordStageStart($input: StartInput!) {
    recordStageStart(input: $input) {
      success
    }
  }
`;

const stageId = 'ct1switch';

export const startGame = (eventId: string, uid: string) => {
  sendStageStart(app, eventId, uid, stageId);
};

export const submitScore = (eventId: string, uid: string, score: number) => {
  sendStageScore(app, eventId, uid, stageId, score);
};

export const finishGame = (eventId: string, uid: string) => {
  sendStageEnd(app, eventId, uid, stageId);
};
