import Stage1Rule from '../static/png/stage1/Stage1_rule.png';
import Stage1Title from '../static/png/stage1/Stage1_title.png';
import Stage2Rule from '../static/png/stage2/Stage2_rule.png';
import Stage2Title from '../static/png/stage2/Stage2_title.png';
import Stage3Rule from '../static/png/stage3/Stage3_rule.png';
import Stage3Title from '../static/png/stage3/Stage3_title.png';
import Stage4Rule from '../static/png/stage4/Stage4_rule.png';
import Stage4Title from '../static/png/stage4/Stage4_title.png';
import Stage5Rule from '../static/png/stage5/Stage5_rule.png';
import Stage5Title from '../static/png/stage5/Stage5_title.png';

interface ImageSrc {
  title: string;
  rule: string;
  video_start: string;
  video_end: string;
}

export const instructionImgSrc: { [v: number]: ImageSrc } = {
  1: {
    title: Stage1Title,
    rule: Stage1Rule,
    video_start:
      'https://storage.googleapis.com/specc-ct-movie/1-1Switch_%E3%83%AB%E3%83%BC%E3%83%AB%E8%AA%AC%E6%98%8E.mp4',
    video_end:
      'https://storage.googleapis.com/specc-ct-movie/1-2Switch_%E3%82%B9%E3%83%86%E3%83%BC%E3%82%B7%E3%82%99%E7%B5%82%E3%82%8F%E3%82%8A.mp4',
  },
};
