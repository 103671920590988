import { User } from 'firebase/auth';
import actionCreatorFactory from 'typescript-fsa';

import { CustomClaimContent } from '../../api/firestoreTypes';

const actionCreator = actionCreatorFactory();

export const setUser = actionCreator<{ user: User | null }>('AUTH_SET_USER');
export const setSelectedOrgId = actionCreator<{ orgId: string | null }>(
  'AUTH_SET_SELECTED_ORG_ID'
);
export const setUserCustomClaim = actionCreator<{
  claimContent: CustomClaimContent | null;
}>('AUTH_SET_USER_CUSTOM_CLAIM');
