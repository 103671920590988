import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { StageId } from '../../api/stages';
import {
  StageManagerPhase,
  StageManagerState,
} from '../../components/eventProvider/stageProviders/StageManager';
import { gameActions } from '../actions/gameActions';

export interface GameState {
  hasStageFinished: { [v: number]: boolean };
  recordedStageStartTime?: number;
  stageManagerState: StageManagerState;
}

const initState: GameState = {
  hasStageFinished: {
    1: false,
  },
  recordedStageStartTime: undefined,
  stageManagerState: {
    phase:
      process.env.REACT_APP_STAGE_DEBUG === 'true'
        ? StageManagerPhase.CONTENT
        : StageManagerPhase.INSTRUCTION,
    startButtonActive: true,
  },
};

const gameReducer = reducerWithInitialState<GameState>(initState)
  .case(gameActions.setHasStageFinished, (state, payload): GameState => {
    return {
      ...state,
      hasStageFinished: {
        ...state.hasStageFinished,
        [payload.stage]: payload.finished,
      },
    };
  })
  .case(gameActions.setRecordedStageStartTimes, (state, payload): GameState => {
    return {
      ...state,
      recordedStageStartTime: payload[1],
    };
  })
  .case(gameActions.setStageManagerState, (state, payload): GameState => {
    return {
      ...state,
      stageManagerState: {
        ...state.stageManagerState,
        ...payload,
      },
    };
  });

export default gameReducer;
