// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/firestore';

import './index.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import { initializeApp } from 'firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const firestoreSettings = {
  host: process.env.REACT_APP_FIREBASE_FIRESTORE_HOST || undefined,
  // ignoreUndefinedProperties: true,
  ssl: process.env.REACT_APP_FIREBASE_FIRESTORE_HOST ? false : undefined,
};
export const db = initializeFirestore(app, firestoreSettings);

if (process.env.REACT_APP_LOCAL_APOLLO_DEBUG === 'true') {
  const functions = getFunctions(app);
  connectFunctionsEmulator(functions, 'localhost', 5000);
}

export const parentURI = 'https://specc-dev.riddler.co.jp';

Sentry.init({
  dsn: 'https://ee00d7e2de7d494ebede8b1a782d8611@o1309995.ingest.sentry.io/6556803',
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
